<script setup>
import colors from 'material-colors'
import { ref, reactive, computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import AppPalette from '@/views/config/theme/Palette.vue'

const store = useStore()

const palette = reactive(
  {
    clientColors: [
      {
        id: 1,
        color: computed(() => store.getters.clientThemeColor),
        name: 'Cor personalizada'
      }
    ],
    emixColors: [
      {
        id: 1,
        color: '#45afae',
        name: 'Cor e.Mix'
      }
    ]
  }
)

const ignoreColors = ref([
  '50',
  '100',
  '200',
  '300',
  '400',
  '800',
  '900',
  'a100',
  'a200',
  'a400',
  'a700'
])

const materialColors = computed(() => {
  const array = []

  Object.keys(colors).forEach(i => {
    Object.keys(colors[i]).forEach(j => {
      if (!ignoreColors.value.includes(j)) {
        array.push({
          id: i + j,
          color: colors[i][j]
        })
      }
    })
  })

  return array.slice(0, 51)
})

const colorSelected = computed(() => {
  const color = store.getters.primaryColor

  return {
    palette: color.idPalette,
    color: color.id
  }
})
</script>

<template>
  <div :class="$style.toolcolor">
    <template v-if="store.getters.clientThemeColor">
      <h2 :class="$style.toolcolorSubtitle">Cores personalizadas</h2>
      <app-palette :data="palette.clientColors" :selecteds="colorSelected" :idPalette="1" />
    </template>

    <h2 :class="$style.toolcolorSubtitle">e.Mix</h2>
    <app-palette :data="palette.emixColors" :selecteds="colorSelected" :idPalette="2" />

    <h2 :class="$style.toolcolorSubtitle">Cores Padrão</h2>
    <app-palette :data="materialColors" :selecteds="colorSelected" :idPalette="3" />
  </div>
</template>

<style lang="scss" module>
.lineselect {
  margin-top: $theme-padding * 2;
}

.toolcolor {
  &__subtitle {
    padding: $theme-padding 0;
    margin-bottom: math.div($theme-padding, 2);
    color: #000;
    font-family: 'Barlow', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;

    :global(.nightlymode) & {
      color: #fff;
    }
  }
}
</style>
