<template>
  <app-config>
    <app-box padding>
      <app-box margin>
        <app-title label="Modo escuro" />
      </app-box>

      <app-box background margin>
        <app-text class="mb-5">
          O modo escuro altera as cores brilhantes padrões do plano de fundo e
          dos elementos no {{ $store.getters.user.data.appName }} para cores mais escuras, que são mais
          confortáveis aos olhos em ambientes com baixa luz ou se você preferir
          uma interface menos brilhante.
        </app-text>

        <app-input-label :margin="false" background>
          Alternar modo escuro
          <app-switch
            :active="storeNightlyMode"
            big
            class="ml-3"
            v-on:change="toggleNightlyMode()"
          />
        </app-input-label>
      </app-box>
    </app-box>
  </app-config>
</template>

<script>
import AppConfig from '@/views/config/Index.vue'

export default {
  components: {
    AppConfig
  },

  computed: {
    storeNightlyMode () {
      return this.$store.getters.nightlyMode
    }
  },

  methods: {
    toggleNightlyMode () {
      this.$store.commit('toggleNightlyMode')
    }
  }
}
</script>
