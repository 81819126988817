import axios from 'axios'

const moduleSubjectAttribute = {
  state: () => ({
    options: {},
    controller: null,
    loading: false
  }),
  getters: {
    getOptions(state) {
      return state.options
    },
  },
  mutations: {
    SET_OPTIONS(state, { identifier, data }) {
      state.options[identifier] = data
    },
    CLEAR_OPTIONS(state, identifier) {
      delete state.options[identifier]
    },
    SET_CONTROLLER(state, data) {
      state.controller = data
    },
  },
  actions: {
    async loadSubjectAttributesList(
      { commit, getters, state, dispatch },
      { page, term, attrId, extraParams = '' }
    ) {
      if (state.controller) state.controller.abort()
      commit('SET_CONTROLLER', new AbortController())

      const params = `${attrId}${extraParams}`

      const identifier = term ? `${params}/${term}` : params

      await axios
        .get(`${getters.api}/SubjectAttribute/related/${params}`, {
          params: {
            page,
            fetch: 50,
            term
          },
          signal: state.controller.signal,
        })
        .then(({ data }) => {
          commit('SET_OPTIONS', {
            identifier,
            data: {
              itens: page > 1 ? [...state.options[identifier].itens, ...data.itens] : data.itens,
              currentPage: page,
              totalResults: data.totalResults,
              ...(term ? { term } : {})
            },
          })
        })
        .catch((error) => {
          if (error.code !== 'ERR_CANCELED') {
            if ([401, 404].includes(error.response.status)) {
              const errorMessage = error.response.data?.Errors[0]?.Message || 'Erro ao exibir a lista'

              commit('SET_OPTIONS', {
                identifier,
                data: { error: errorMessage }
              })

              dispatch('notification', {
                type: 'error',
                text: errorMessage
              })
            } else {
              dispatch('notification', {
                type: 'error',
                text: 'Ocorreu um erro interno em nosso servidor, tente novamente mais tarde ou contate um de nossos administradores.'
              })
            }
          }
        })
    },
  },
}

export default moduleSubjectAttribute
