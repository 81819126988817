<script setup>
import { useStore } from 'vuex'

const props = defineProps({
  data: Array,
  selecteds: Object,
  idPalette: Number
})

const store = useStore()

const selectItem = (value) => {
  store.dispatch('changePrimaryColor', {
    id: value.id,
    color: value.color,
    idPalette: props.idPalette
  })
}
</script>

<template>
  <div :class="$style.toolcolor">
    <div :class="$style.toolcolorSelector">
      <div
        v-for="item in data"
        :key="item.id"
        :class="[
          $style.toolcolorSelectorColor,
          selecteds.palette == idPalette && selecteds.color == item.id
            ? $style.toolcolorSelectorColorSelected
            : null,
        ]"
        :style="{
          background: item.color ? item.color : null,
        }"
        v-tooltip="item.name || item.id"
        @click="selectItem(item)"
      >
        <transition name="fade-up">
          <app-icon
            v-if="selecteds.palette == idPalette && selecteds.color == item.id"
            glyph="done"
            :class="$style.toolcolorSelectorIcon"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.toolcolor {
  &__selector {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 (-$theme-padding);

    &__color {
      $size: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 $theme-padding $theme-padding;
      width: $size;
      height: $size;
      border-radius: math.div($size, 2);
      cursor: pointer;
      transition: 0.2s ease;
      transition-property: transform;

      &--selected,
      &:hover {
        transform: scale(1.2);
      }
    }

    &__icon {
      width: 30px;
      height: 30px;
      fill: #ffffff;
    }
  }
}
</style>
