<template>
  <div ref="target">
    <select
      ref="validation"
      :style="{
        position: 'absolute',
        top: '0px',
        left: '0px',
        'z-index': 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }"
      :required="config.required && !valuesSelectedFull.length && !readonly"
      @keydown.space.prevent="toggleSelect($event)"
      @keydown.down.prevent="toggleSelect($event)"
      @keydown.enter.prevent="toggleSelect($event)"
      @focus="focused = true"
      @blur="focused = false"
      @change.stop
      :tabindex="readonly ? -1 : null"
      :readonly="readonly"
    >
      <option value="">noselected</option>
    </select>

    <app-tooltip
      :label="config.tooltip ? config.tooltip.message : null"
      :active="!config.params && config.tooltip ? true : false"
    >
      <div
        :class="[
          $style.container,
          $style.containerForm,
          readonly ? $style.containerDisabled : null,
          listOpened || focused ? $style.containerActive : null,
        ]"
        :id="attrId + '' + index"
      >
        <div
          class="d-flex align-items-center"
          :class="$style.containerFormLoading"
          v-if="config.loading"
        >
          <div class="col-auto pr-3">
            <app-loader-spinner />
          </div>

          <div class="col">Carregando dados...</div>
        </div>
        <div
          v-else
          :class="[$style.selecteds, $style.selectedsForm]"
          :id="'selecteds' + attrId + '' + index"
          @click="toggleSelect($event)"
          @mousedown.prevent
        >
          <label
            v-for="item in valuesSelectedFullFiltered"
            :key="item.id"
            :readonly="readonly"
            :class="$style.selectedsLabel"
          >
            <a
              :class="$style.selectedsLink"
              :href="opentalkmodalId"
              target="blank"
            >
              {{ item.name ? removeTags(item.name) : removeTags(item.label) }}
            </a>
            <app-icon
              glyph="close"
              :class="$style.selectedsIcon"
              @click="removeItem(item.id)"
              v-if="!readonly"
              :size="14"
            />
          </label>

          <app-icon
            :class="[
              $style.containerArrow,
              readonly ? $style.containerArrowDisabled : null,
            ]"
            :size="28"
            glyph="arrow_drop_down"
          />
        </div>

        <transition name="fade-down" tag="span" v-if="!readonly">
          <app-select-list
            :data="notSelecteds"
            :open-list="listOpened"
            :loading-search="loadingSearch"
            :loading-page="loading"
            :have-search="true"
            :have-refresh="true"
            :reference="attrId"
            :error-message="error"
            :talk="true"
            :object-key="{
              id: 'id',
              label: 'name',
            }"
            @change-value="changeValue"
            @handle-scroll="handleScroll"
            @refresh="refreshList"
            @search="search"
            @close="closeList"
            @click.stop
            class="list"
          />
        </transition>
      </div>
    </app-tooltip>
  </div>
</template>

<script>
import EventBus from '@/views/eventBus'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

const striptags = require('striptags')

export default {
  setup (props, { emit }) {
    const target = ref(null)
    const listOpened = ref(false)
    const page = ref(1)

    onClickOutside(target, () => {
      listOpened.value = false
      emit('change-zindex', 0)
    })

    return { target, listOpened, page }
  },

  emits: [
    'change-select',
    'change-zindex',
    'inputIdentifier',
    'change',
    'change-obj',
    'add-attribute-itens',
    'change-attribute-itens'
  ],

  props: {
    api: {
      type: String,
      default: (props) => `SubjectAttribute/related/${props.attrId}`
    },
    itensSelect: {
      type: Array,
      default: () => []
    },
    isMultiple: Boolean,
    config: {
      type: Object,
      default: function (props) {
        return {
          isMultiple: props.isMultiple,
          actions: {
            update: true,
            insert: true
          }
        }
      }
    },
    value: [Array, String, Object, Number],
    opened: {
      type: Boolean,
      default: false
    },
    attrId: Number,
    identifier: String,
    index: [String, Number],
    talkId: Number
  },

  data () {
    return {
      loading: false,
      loadingSearch: false,
      blockLoading: false,
      delay: null,
      totalResults: 0,
      valuesSelectedData: [],
      valuesSelectedFull: [],
      filter: '',
      filterId: '',
      request: null,
      error: null,
      focused: false
    }
  },

  computed: {
    valuesSelectedFullFiltered () {
      const valueSelected = this.valuesSelectedFull.filter((z) => z.id)
      if (valueSelected.length) {
        valueSelected.forEach((el) => {
          if (el.id == 51 || el.id == 52 || el.id == 53) {
            EventBus.$emit('inputIdentifier', el.name)
          }
        })
      }
      return this.valuesSelectedFull.filter((z) => z.id)
    },

    selecteds () {
      return this.itensSelect.filter((value) =>
        this.valuesSelectedData.includes(value.id)
      )
    },

    notSelecteds () {
      return this.itensSelect.filter(
        (value) => !this.valuesSelectedData.includes(value.id)
      )
    },

    totalPage () {
      return Math.ceil(this.totalResults / 50)
    },

    readonly () {
      return (
        this.config.readonly ||
          (this.talkId != 0 && typeof this.talkId != 'undefined'
            ? !this.config.actions.update
            : !this.config.actions.insert) ||
          ((this.attrId == 1560 || this.attrId == 1561) &&
            this.subjectIdentifier == 'carregamentoservico')
      )
    },
    opentalkmodalId () {
      let routetalk = null
      if (
        this.identifier === 'talkid' &&
          this.valuesSelectedFullFiltered.length > 0 &&
          this.config.related?.value
      ) {
        const assunto = Array.isArray(this.config.related.value)
          ? this.config.related.value[0].name
          : this.config.related.value.name

        const idTalk = this.valuesSelectedFullFiltered[0].id

        routetalk = this.$router.resolve({
          path: `/context/talk/${assunto}/${idTalk}`
        })
      }
      return routetalk ? routetalk.href : routetalk
    },
    extraParams() {
      if (Array.isArray(this.config.params)) {
        let mountedQuery = ''
        this.config.params.forEach((item, index) => {
          if (index === 0) {
            const initQuery = `?${item.identifier}=${item.value}`
            mountedQuery = initQuery
          } else {
            const queryContinuation = `&${item.identifier}=${item.value}`
            mountedQuery += queryContinuation
          }
        })

        return mountedQuery
      }
      return this.config.params
        ? `?${this.config.params.identifier}=${this.config.params.value}`
        : ''
    },
    optionsIdentifier () {
      return this.config.params
        ? `${this.attrId}${this.extraParams}`
        : this.attrId
    },
    options () {
      return this.$store.getters.getOptions[
        this.filter
          ? `${this.optionsIdentifier}/${this.filter}`
          : this.optionsIdentifier
      ]
    }
  },

  methods: {
    removeTags (label) {
      return label ? striptags(`${label}`) : ''
    },

    removeItem (value) {
      const index = this.valuesSelectedData.indexOf(value)

      if (index > -1) {
        this.valuesSelectedData.splice(index, 1)
        this.valuesSelectedFull.splice(index, 1)

        this.$emit('change', this.valuesSelectedFull)
      }
    },

    changeValue (value) {
      if (!this.config.isMultiple) {
        this.valuesSelectedFull = []
        this.valuesSelectedData = []
      }

      const obj = this.notSelecteds.find((x) => x.id === value)

      this.valuesSelectedFull.push(JSON.parse(JSON.stringify(obj)))
      this.valuesSelectedData.push(value)

      this.$emit('change', this.valuesSelectedFull)
      this.$emit('change-obj', this.valuesSelectedFull[0])

      this.listOpened = false

      this.$emit('change-zindex', 0)

      setTimeout(() => {
        this.$refs.validation.focus()
      }, 100)
    },

    toggleSelect (e) {
      this.listOpened = !this.listOpened
      this.$emit('change-zindex', 1000)

      if (this.listOpened && this.itensSelect.length == 0) this.init()
      if (!this.listOpened && e?.target) this.$refs.validation.focus()
    },

    closeList () {
      this.toggleSelect()
      this.$refs.validation.focus()
    },

    setItensValue() {
      if (this.options) {
        this.$emit('change-attribute-itens', this.options.itens)
        this.totalResults = this.options.totalResults
      }
    },

    async handleScroll () {
      if (this.page < this.totalPage && !this.blockLoading) {
        this.page = this.options.currentPage + 1

        this.loading = true

        await this.$store.dispatch('loadSubjectAttributesList', {
          page: this.page,
          attrId: this.attrId,
          ...(this.filter ? { term: this.filter } : {}),
          extraParams: this.extraParams
        })

        this.setItensValue()

        this.loading = false
      }
    },

    search (filter) {
      this.filter = filter

      clearTimeout(this.delay)

      if (!this.options?.itens.length) {
        this.delay = setTimeout(
          async () => {
            this.loadingSearch = true
            this.blockLoading = true
            this.page = 1

            await this.$store.dispatch('loadSubjectAttributesList', {
              page: this.page,
              attrId: this.attrId,
              term: this.filter,
              extraParams: this.extraParams
            })

            this.setItensValue()

            this.loadingSearch = false
            this.blockLoading = false
          },
          500)
      } else {
        this.setItensValue()

        this.loadingSearch = false
        this.blockLoading = false
      }
    },

    async init () {
      if (!this.options?.itens?.length) {
        this.loading = true
        this.error = null

        await this.$store.dispatch('loadSubjectAttributesList', {
          page: this.page,
          attrId: this.attrId,
          extraParams: this.extraParams
        })

        if (this.options?.error) {
          this.error = this.options?.error
        }

        this.loading = false
      }

      this.setItensValue()
    },

    async refreshList () {
      this.$store.commit('CLEAR_OPTIONS', this.optionsIdentifier)
      this.$emit('change-attribute-itens', [])
      this.totalResults = 0
      this.page = 1
      this.loading = true
      this.error = null

      await this.$store.dispatch('loadSubjectAttributesList', {
        page: this.page,
        attrId: this.attrId,
        extraParams: this.extraParams
      })

      if (this.options?.error) {
        this.error = this.options?.error
      }

      this.setItensValue()
      this.loading = false
    }
  },

  mounted () {
    if (
      this.$props.config.preSelect === 1 &&
        this.$store.getters.filter &&
        this.$store.getters.templateType !== 6 &&
        this.valuesSelectedFull.length === 0
    ) {
      this.loading = true
      this.error = null
    }

    if (!this.value && this.opened) this.toggleSelect()
  },

  beforeMount () {
    this.filterId =
        this.$store.getters.templateFilterId.subjectAttributeId === this.attrId
          ? this.$store.getters.templateFilterId.value
          : ''

    this.$store.commit('setTemplateFilterId', '')

    if (this.value && !Array.isArray(this.value)) {
      this.valuesSelectedData.push(this.value.id)
      this.valuesSelectedFull.push(this.value)
    }

    if (Array.isArray(this.value)) {
      this.value.forEach((element) => {
        this.valuesSelectedData.push(element.id)
      })

      this.valuesSelectedFull = this.value
    }
  },

  watch: {
    'config.params.value': {
      handler: function () {
        this.$emit('change-attribute-itens', [])
      },
      deep: true
    },

    value: {
      handler: function (value) {
        if (value == '' || value == null) {
          this.valuesSelectedData = []
          this.valuesSelectedFull = []
        } else if (Array.isArray(value)) {
          this.valuesSelectedData = []
          this.valuesSelectedFull = []

          value.forEach((val) => {
            this.valuesSelectedData.push(val.id)
            this.valuesSelectedFull.push(val)
          })
        } else if (!Array.isArray(value)) {
          this.valuesSelectedData = [value.id]
          this.valuesSelectedFull = [value]
        }
      },

      deep: true
    }
  }
}
</script>

<style lang="scss" module>
  $label-color: $md-grey-800;
  $label-color-nightly: $md-grey-200;

  $background-color: $md-grey-300;
  $background-color-nightly: $md-grey-600;

  $background-container: white;
  $background-container-nightly: lighten($md-grey-900, 5%);

  .container {
    overflow: hidden;
    display: flex;
    min-height: 40px;
    background: $background-container;

    :global(.nightlymode) & {
      background: $background-container-nightly;
    }

    &--form {
      min-height: 50px;
      border-radius: 5px;
      box-shadow: inset 0 0 0 2px $md-grey-400;

      :global(.nightlymode) & {
        background: $md-grey-900;
        box-shadow: inset 0 0 0 2px $md-grey-600;
      }

      &__loading {
        @include font-sizer(12);

        :global(.nightlymode) & {
          color: white;
        }
      }
    }

    &--disabled {
      background: $md-grey-100;
      pointer-events: none;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }
    }

    &--active {
      box-shadow: inset 0 0 0 2px var(--theme-color-1);

      :global(.nightlymode) & {
        box-shadow: inset 0 0 0 2px var(--theme-color-1);
      }
    }

    &__arrow {
      position: absolute;
      right: 6px;
      bottom: calc(50% - 14px);
      fill: $md-grey-900;

      :global(.nightlymode) & {
        fill: $md-grey-400;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
  }

  .selecteds {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px ($theme-padding * 2) 6px (math.div($theme-padding, 2));
    width: 100%;

    a:hover {
      color: initial;
    }

    a {
      color: initial;
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
      margin-bottom: math.div($theme-padding, 2);
      margin-right: math.div($theme-padding, 2);
      padding: 0 (math.div($theme-padding, 2));
      max-width: 100%;
      background: $background-color;
      color: $label-color;
      border-radius: 22px;
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 24px;

      :global(.nightlymode) & {
        background: $background-color-nightly;
        color: $label-color-nightly;
      }

      &[readonly] {
        background: $md-grey-300;
        box-shadow: inset 0 0 0 2px transparent;
        cursor: default;

        :global(.nightlymode) & {
          background: $md-grey-700;
        }
      }
    }

    &__link {
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__icon {
      flex-shrink: 0;
      fill: $label-color;
      cursor: pointer;
      transition: fill 0.3s ease;

      &:hover {
        fill: $md-grey-600;
      }

      :global(.nightlymode) & {
        fill: $label-color-nightly;

        &:hover {
          fill: $label-color;
        }
      }
    }
  }

  $color-item: $md-grey-800;
  $color-item-nightly: $md-grey-300;

  $background-list: $md-grey-100;
  $background-list-nightly: $md-grey-800;

  $background-item: $md-grey-800;
  $background-item-nightly: $md-grey-700;

  $background-item-hover: $md-grey-400;
  $background-item-hover-nightly: $md-grey-700;

  $background-input-filter: $md-grey-800;
  $background-input-filter-nightly: $md-grey-800;

  .select {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    min-width: 300px;
    border-radius: 5px;

    &__scroll {
      max-height: 200px;
      @include scrollbar;
      overflow: auto;
    }

    &__list {
      padding: (math.div($theme-padding, 4)) (math.div($theme-padding, 2));
      margin: 0;
      background: $background-list;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      :global(.nightlymode) & {
        background: $background-list-nightly;
      }

      list-style: none;

      &--form {
        background: $background-list;

        :global(.nightlymode) & {
          background: $md-grey-900;
        }
      }

      &--active {
        box-shadow: inset 0 0 0 2px $md-blue-500;
      }

      &__item {
        padding: (math.div($theme-padding, 4)) (math.div($theme-padding, 2));
        margin: (math.div($theme-padding, 2)) 0;
        background: transparent;
        border-radius: 3px;
        font-family: 'Raleway', sans-serif;
        font-size: 1.2rem;
        line-height: 20px;
        color: $color-item;
        cursor: pointer;
        overflow: hidden;
        transition: background 0.4s ease;

        :global(.nightlymode) & {
          background: transparent;
          color: $color-item-nightly;
        }

        &:hover {
          background: $background-item-hover;

          :global(.nightlymode) & {
            background: $background-item-hover-nightly;
          }
        }
      }
    }

    &__filter {
      input {
        padding: (math.div($theme-padding, 2)) (math.div($theme-padding, 2));
        width: 100%;
        background: $background-input-filter;
        color: $color-item;
        font-family: 'Raleway', sans-serif;
        font-size: 1.2rem;
        line-height: 20px;

        :global(.nightlymode) & {
          background: $background-input-filter-nightly;
          color: $color-item-nightly;
        }
      }

      &--form {
        background: white;

        :global(.nightlymode) & {
          background: $md-grey-900 !important;
        }
      }
    }
  }
</style>
