<template>
  <div v-if="ready">
    <app-notifications />

    <div :class="$style.appConnection" v-if="!online">
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <app-icon glyph="cloud_off" :class="$style.appConnectionIcon" />
        </div>

        <div class="col">
          <div :class="$style.appConnectionLabel">
            Ops! Parece que você não tem uma conexão com a internet ativa no
            momento. Algumas funções do {{ $store.getters.user.data.appName }} estarão indisponíveis.
            Verifique a sua conexão e tente novamente.
          </div>
        </div>
      </div>
    </div>

    <div
      :class="[$style.appConnection, $style.appConnectionOnline]"
      v-if="online && reconnected"
    >
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <app-icon glyph="cloud_queue" :class="$style.appConnectionIcon" />
        </div>

        <div class="col">
          <div :class="$style.appConnectionLabel">
            Que legal, sua conexão está ativa novamente!
          </div>
        </div>
      </div>
    </div>

    <router-view v-slot="{ Component }">
      <transition name="fade">
        <div
          class="app"
          :class="$style.app"
          v-if="user"
          key="app"
        >
          <svg-icons />

          <app-menu />

          <div :class="$style.appContent">
            <app-header
              @logout-user="logoutUser"
            />

            <div
              :class="[$style.appView, isHidden ? $style.appViewHidden : '']"
            >
              <keep-alive>
                <component :is="Component" :key="route.fullPath" />
              </keep-alive>
            </div>
          </div>
        </div>

        <div
          v-else
          :class="$style.appLoading"
        >
          <app-loader
            :class="$style.appLoadingIcon"
            :active="login.loading"
            :error="login.error"
            resourceType="login"
          />
          <app-button
            v-if="login.error"
            label="Tentar novamente"
            color="emix-color-1"
            @click="redirectLogin"
          />
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import EventBus from '@/eventBus'
import useWebSocket from '@/composables/useWebSocket'
import { useMagicKeys } from '@vueuse/core'
import { defineAsyncComponent, ref, reactive, computed, watch, onMounted, provide, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import slug from 'slug'
import axios from 'axios'

const AppNotifications = defineAsyncComponent(() =>
  import('@/layout/Notifications.vue')
)
const AppHeader = defineAsyncComponent(() => import('@/components/LayoutHeader.vue'))
const AppMenu = defineAsyncComponent(() => import('@/layout/Menu.vue'))
const SvgIcons = defineAsyncComponent(() => import('@/icons/SvgIcons.vue'))

const store = useStore()
const route = useRoute()

const { alt, g, h, j } = useMagicKeys()
watchEffect(() => {
  if (alt.value && g.value) store.commit('toggleNightlyMode')
  if (alt.value && h.value) store.commit('toggleFullscreenMode')
  if (alt.value && j.value) store.commit('toggleMenuActive')
})

const online = ref(navigator.onLine || false)
const reconnected = ref(false)

const login = reactive({
  loading: true,
  error: false,
})

window.addEventListener('offline', () => {
  online.value = false
})

window.addEventListener('online', () => {
  online.value = true
  reconnected.value = true

  setTimeout(() => {
    reconnected.value = false
  }, 5000)
})

const ready = computed(() => store.getters.ready)
const logoff = computed(() => store.getters.logoff)
const storeNightlyMode = computed(() => store.getters.nightlyMode)
const user = computed(() => store.getters.user.active)

watch([user, storeNightlyMode], (newValue) => {
  if (newValue[0] && newValue[1]) {
    document.body.classList.add('nightlymode')
  } else {
    document.body.classList.remove('nightlymode')
  }
}, { immediate: true })

const isHidden = ref(false)

const token = computed(() => store.getters.token)
const { ws } = useWebSocket(store)

const startWS = async () => {
  await ws.start()
    .then()
    .catch((err) => {
      console.log('Error while starting connection: ' + err)

      if (store.getters.token) {
        store.dispatch('notification', {
          type: 'error',
          title:
              'Erro ao tentar iniciar a conexão com o servidor de tempo real',
          text: 'Não foi possível iniciar a conexão com o servidor de tempo real de <b>Mensagens (Diálogos)</b>. Recomenda-se o recarregamento da página.',
          error: err,
          timeout: 60000
        })
      }
    })

  ws.onreconnecting((error) => {
    store.dispatch('notification', {
      type: 'alert',
      title: 'Tentando reconexão com servidor em tempo real',
      text: `Conexão com servidor em tempo real perdida. Erro: "${error}". Reconectando...`,
      timeout: 45000
    })

    console.log(
        `Conexão com servidor em tempo real perdida. Erro: "${error}". Reconectando.`
    )
  })

  ws.onreconnected((connectionId) => {
    store.dispatch('notification', {
      title: 'Conexão com servidor em tempo real reestabelecida',
      text: 'Conexão com servidor em tempo real reestabelecida.',
      timeout: 45000
    })

    console.log('Conexão com servidor em tempo real reestabelecida.')
  })

  ws.onclose((error) => {
    console.log(error)

    store.dispatch('notification', {
      type: 'error',
      title: 'Conexão com servidor em tempo real perdida',
      text: 'Conexão com servidor em tempo real perdida permanentemente. Recomenda-se o recarregamento da página.',
      error,
      timeout: 60000
    })
  })
}

watch(token, (newValue) => {
  if (newValue) startWS()
})

if (token.value && !ws.connectionStarted) startWS()

provide('webSocket', ws)

EventBus.$on('context_handle_talk', (data) => {
  EventBus.$emit(
      `context_handle_talk_grid_filter_list_${slug(route.fullPath)}`,
      data
  )
  EventBus.$emit(
      `context_handle_talk_grid_view_${slug(route.fullPath)}`,
      data
  )
})

// Logout user
const logoutUser = () => {
  removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
  store.commit('logoutUser', true)
}

// Controlando fechamento e atualizacao de página
// para emitir aviso sobre perda de dados da GRID
const gridItemsToSave = computed(() => store.getters.itemsToSaveGrid.length > 0)

watch(
  () => gridItemsToSave.value,
  (newValue) => {
    if (newValue) {
      addEventListener('beforeunload', beforeUnloadListener, { capture: true })
    } else {
      removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
    }
  }
)

const beforeUnloadListener = (event) => {
  event.preventDefault()
  event.returnValue = 'Há dados não salvos, deseja continuar?'
  return event.returnValue
}

onMounted(() => {
  if (window.location.href.includes('login.aspx')) {
    window.history.replaceState(history.state, document.title, '/#/')
  }

  let authorizationCode
  if (window.location.search.includes('?authorizationCode')) {
    authorizationCode = window.location.search.split('?authorizationCode=')[1]

    window.history.replaceState(history.state, document.title, '/#/')

    const payload = {
      authorizationCode,
      clientId: store.getters.emixLogin.clientId,
    }

    axios
      .post(`${store.getters.api}/Account/RequestJwt`, payload)
      .then(async ({ data }) => {
        const token = data.token.split('.')

        const dataUser = JSON.parse(
          decodeURIComponent(escape(atob(token[1])))
        )

        const tokenAccess = data.token

        const replace = dataUser.requireDigitalSignature
          .replace('[', '')
          .replace(']', '')

        const user = {
          data: {
            keepConnected: false,
            name: dataUser.name,
            accountId: dataUser.accountId,
            requireDigitalSignature: replace.split(','),
            roomId: dataUser.roomId,
            companyId: dataUser.companyId,
            token: tokenAccess,
            isAdmin: dataUser.isAdmin.toLowerCase() == 'true',
            companyIdentifier: data.getCompanyDataResponse.companyIdentifier.toLowerCase(),
            companyName: data.getCompanyDataResponse.companyName,
            appIdentifier: data.getAppResponse.appIdentifier.toLowerCase(),
            appName: data.getAppResponse.appName
          }
        }

        store.commit('loginUser', user.data)

        store.dispatch('applyClientThemeColor', data.getCompanyDataResponse.themeColor)

        localStorage.setItem('TALKTURE_SHARE_CONNECTION', JSON.stringify(user))
        localStorage.removeItem('TALKTURE_SHARE_CONNECTION')

        setDocumentTitle()
      })
      .catch((e) => {
        login.loading = false
        login.error = true
        throw e
      })
  } else {
    const token =
      localStorage.getItem('talkture_token') ||
      sessionStorage.getItem('talkture_token')

    if (!token) {
      store.commit('logoutUser', true)
    }

    setDocumentTitle()
  }
})

const redirectLogin = () => {
  window.location.href = store.getters.emixLogin.redirectLogin
}

const setDocumentTitle = () => {
  if (store.getters.user.data.appName) {
    document.title = document.title.replace('e.Mix', `${store.getters.user.data.appName}`)
  }
}
</script>

<style lang="scss" module>
  @mixin transitionFade() {
    &-enter-active,
    &-leave-active {
      transition: 0.2s ease;
      transition-property: opacity, transform;
    }

    &-enter-from,
    &-leave-active {
      opacity: 0;
    }
  }

  :global {
    .translate-up {
      &-enter-active,
      &-leave-active {
        transition: 0.1s ease;
        transition-property: opacity, transform;
      }

      &-enter-from,
      &-leave-active {
        opacity: 0;
        transform: translate3d(0, 2px, 0);
      }
    }

    .fade {
      @include transitionFade();
    }

    .fade-move {
      @include transitionFade();

      &-enter-from {
        transform: translateY(20px);
      }

      &-leave-active {
        transform: translateY(20px);
      }
    }

    .fade-right {
      @include transitionFade();

      &-enter-from {
        transform: translateX(math.div(-$theme-padding, 2));
      }

      &-leave-active {
        transform: translateX(math.div($theme-padding, 2));
      }
    }

    .fade-right-short {
      @include transitionFade();

      &-enter-from {
        transform: translateX(-2px);
      }

      &-leave-active {
        transform: translateX(2px);
      }
    }

    .fade-left {
      @include transitionFade();

      &-enter-from {
        transform: translateX(math.div($theme-padding, 2));
      }

      &-leave-active {
        transform: translateX(math.div(-$theme-padding, 2));
      }
    }

    .fade-left-short {
      @include transitionFade();

      &-enter-from {
        transform: translateX(2px);
      }

      &-leave-active {
        transform: translateX(-2px);
      }
    }

    .fade-up {
      @include transitionFade();

      &-enter-from,
      &-leave-active {
        transform: translateY(math.div($theme-padding, 2));
      }
    }

    .fade-up-short {
      @include transitionFade();

      &-enter-from,
      &-leave-active {
        transform: translateY(2px);
      }
    }

    .fade-down {
      @include transitionFade();

      &-enter-from,
      &-leave-active {
        transform: translateY(math.div(-$theme-padding, 2));
      }
    }

    .fade-down-short {
      @include transitionFade();

      &-enter-from,
      &-leave-active {
        transform: translateY(-2px);
      }
    }
  }

  .app {
    $app-background: $md-grey-100;
    $app-background-nightly: darken($md-grey-900, 5%);
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: $app-background;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;

    :global(.nightlymode) & {
      background: $app-background-nightly;
    }

    &__connection {
      z-index: 1000000;
      display: flex;
      justify-content: center;
      padding: $theme-padding;
      background: $md-red-500;

      &--online {
        background: $md-green-500;
      }

      &__icon {
        margin-right: $theme-padding;
        fill: white;
      }

      &__label {
        font-family: 'Raleway', sans-serif;
        font-size: 1.4px;
        color: white;
        font-weight: 600;
      }
    }

    &__content {
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      width: 100%;

      :global(.nightlymode) & {
        background-color: $app-background-nightly;
      }
    }

    &__view {
      overflow: hidden auto;
      display: flex;
      flex-grow: 1;
      width: 100%;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;

        &-thumb {
          background-color: $md-grey-400;

          :global(.nightlymode) & {
            background-color: $md-grey-700;
          }
        }
      }

      &--hidden {
        overflow: hidden hidden;
      }
    }

    &__loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;

      &__icon {
        min-height: 0 !important;
        height: auto !important;
      }
    }
  }
</style>
